<template>
    <div class="user-sort-content pd-10 h-calc-60">
        <div class="fresh-auto">
            <el-radio-group v-model="orderType" @change="lists">
                <el-radio-button  v-for="(item, index) in sortType" :label="item.value" >{{item.name}}</el-radio-button>
            </el-radio-group>
            <el-button type="primary" @click="updateData">更新数据</el-button>
        </div>
        <div class="sort-main">
            <el-table :data="tableData">
              <el-table-column label="昵称" prop="nickname" align="center" width="120"></el-table-column>
              <el-table-column label="头像" align="center">
                  <template #default="scope">
                      <div class="image-box">
                        <image-c :src="scope.row.avatar_url" :alt="scope.row.nickname"></image-c>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column  label="积分" prop="integral"></el-table-column>
              <el-table-column label="累计签到次数" prop="sign_num"></el-table-column>
            </el-table>
        </div>
        <page-component :page="page" :limit="limit" :total="total" @change="changePage" />
    </div>

</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue"
import {userSortList} from "@/utils/apis";
import PageComponent from "@/components/common/PageComponent";
import ImageC from "@/components/common/ImageC"

const tableData = ref([])
const sortType = ref([
  {value: 'sign_num', name: '已签到'},
  {value: 'integral', name: '积分'}
])
const page = ref(1)
const limit = ref(10)
const total = ref(0)

const orderType = ref("sign_num")

const updateData = () => {
  lists()
}

const changePage = (val) => {
    console.log('val', val);
    page.value = val
    lists()
}

const lists = async () => {
  let params = {
    paging: 1,
    page: page.value,
    pageSize: limit.value,
    order_type: orderType.value
  }
  const {data, msg} = await userSortList(params)
  tableData.value = data.list
  total.value = data.total
}
let instance = null;

const setInstance = () => {
    instance = setInterval(() => {
        lists()
    }, 5000)
}

const clearInstance = () => {
    clearInterval(instance)
}

onMounted(() => {
    lists()
    setInstance()
})
onUnmounted(() => {
    console.log('离开');
    clearInstance()
})

</script>

<style scoped lang="scss">
.fresh-auto {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.sort-main {
    margin: 10px 0;
    height: calc(100% - 92px);
}
</style>